<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="Equipos TI"
            :filter="filter"
            search-input
            add
            edit
            remove
            @save="save($event)"
            ref="scrudEquipment"
            :configuration="this.$fun.isAdmin()"
            nameScrud="scrudEquipment"
        >
            <template v-slot:options>
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn
                        v-on="on"
                        text
                        black 
                        @click="openMassRegistrations()"
                        small
                        >
                            <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Registro Masivo</span>
                </v-tooltip>
            </template>
            <template v-slot:filter>
                <v-container>
                    <v-row style="margin-bottom: 5px;">
                        <v-col cols="12" class="s-col-form" md="3">
							<s-select-definition                                
							:def="1531"
							label="Tipo Asignación"
							v-model="filter.CgeTypeAssigns"
							clearable
							/>
						</v-col>
                        <v-col cols="12" class="s-col-form" md="3" v-if="filter.CgeTypeAssigns == 1 || filter.CgeTypeAssigns == 2">
							<s-select-area 
								label="Area"
								v-model="filter.AreID"
							/>
						</v-col>
                        <v-col class="s-col-form" cols="12" md="3" v-if="filter.CgeTypeAssigns == 3">
                            <s-select-definition                                
                            :def="1534"
                            label="Zona"
                            v-model="filter.EanZone"
                            />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-date
                                v-model="filter.CgeDateFrom"
                                label="Fecha desde"
                                clearable
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-date
                                v-model="filter.CgeDateUntil"
                                label="Fecha hasta"
                                clearable
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
							:def="1541"
							label="Estado"
							v-model="filter.CgeStatus"
							clearable
							/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:AsgCgeDate="{ row }">
				{{row.CgeDate.split("T")[0]}}
			</template>
            <template v-slot:AsgCharacteristicsOrAccessory="{ row }">
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" x-small style="margin-right: 10px;" color="primary" @click="AsgCharacteristics(row)">
                            <v-icon>mdi-format-list-bulleted-type</v-icon>
                        </v-btn>
                    </template>
                    <span>Características</span>
                </v-tooltip>
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" x-small style="margin-right: 10px;" color="success" @click="AsgAccessory(row)">
                            <v-icon>mdi-playlist-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Accesorios</span>
                </v-tooltip>
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" x-small color="warning" @click="AsgLicense(row)">
                            <v-icon>mdi-license</v-icon>
                        </v-btn>
                    </template>
                    <span>Licencias</span>
                </v-tooltip>
			</template>
            <template v-slot:AsgCgeAssigns="{ row }">
                <v-chip x-small :color="row.CgeAssigns == 1 ? 'success' : 'error'">
                    {{ row.CgeAssigns == 1 ? "Asignable" : "No Asignable" }}
                </v-chip>
			</template>
            <template v-slot:AsgStatusName="{ row }">
                <v-tooltip top="" v-if="row.NtpFullName || row.AreName || row.EanZoneName">
                    <template v-slot:activator="{ on }">
                        <v-chip x-small
                            v-on="on"
                            :color="
                            row.CgeStatus === 1 ? 'success' : 
                            row.CgeStatus === 2 ? 'yellow' : 
                            row.CgeStatus === 3 ? 'error' : 'white'"
                        >
                        {{ row.CgeStatusName }}&nbsp;<v-icon>mdi-information-outline</v-icon>
                        </v-chip>
                    </template>
                    <span style="display: flex; flex-direction: column; align-items: center; justify-content: center;">{{ row.NtpFullName ? row.NtpFullName : row.AreName ? row.AreName : row.EanZoneName }} <br> <span style="font-size: 10px;">{{ row.NtpFullName ? row.AreName : '' }}</span> </span>
                </v-tooltip>
                <v-chip x-small 
                    :color="
                    row.CgeStatus === 1 ? 'success' : 
                    row.CgeStatus === 2 ? 'yellow' : 
                    row.CgeStatus === 3 ? 'error' : 'white'"
                    v-else>
                {{ row.CgeStatusName }}
                </v-chip>
			</template>
            <template v-slot:AsgCgeIDAnt="{ row }">
                <v-tooltip top="" v-if="row.CgeIDAnt">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span style="font-size: 12px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                        <span style="font-size: 12px;">ID : {{ row.CgeIDAnt}}</span>
                        <span style="font-size: 14px;">{{ row.CgeTypeEquipmentNameAnt }}</span>
                        <span style="font-size: 12px;">Serie : {{ row.CgeSerieNameAnt }}</span>
                    </span>
                </v-tooltip>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >                                        
                         <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                                :def="1530"
                                label="Equipo"
                                v-model="props.item.CgeTypeEquipment"
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                                :def="1531"
                                label="Tipo Asignación"
                                v-model="props.item.CgeTypeAssigns"
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                                :def="1533"
                                label="Marca"
                                v-model="props.item.CgeBrand"
                                :add="$fun.isAdmin()"
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-select-definition                                
                            :def="1540"
                            label="Modelo"
                            v-model="props.item.CgeModel"
                            :add="$fun.isAdmin()"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-text
                                :disabled="props.item.CgeID ? true : false"                             
                                label="Serie" 
                                v-model="props.item.CgeSerie"/>
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="3">
                            <s-date
                                v-model="props.item.CgeDate"
                                label="Fecha Adquisición"
                                />
                        </v-col>
                        <v-col cols="12" class="s-col-form" md="2" v-if="props.item.CgeID ? assignFun(props.item) : addEvent()">
                            <v-switch label="Asignable" v-model="CgeAssigns" dense hide-details />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>

        <v-dialog persistent v-model="openMass" v-if="openMass" max-width="900px">
            <v-card>
                <v-container>
                    <v-row >
                        <v-col >
                        <s-toolbar   
                            no-border 
                            dark 
                            label="Carga Masiva"
                            color="#8e8f91"
                            :clear="!loading"
                            @clear="clear()"
                            :close="!loading"
                            @close="openMass = false"
                        >

                        </s-toolbar>
                            
                            <v-card :loading="loading">
                                <v-col>
                                    <v-row>
                                    <v-col >
                                        <s-import-excel
                                            v-if="dialogExcel"
                                            :headers = "headers"
                                            @loaded	="saveMassive($event)"
                                        ></s-import-excel>
                                    </v-col>
                                    </v-row>
                                </v-col>
                            </v-card>				
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialogCharacteristics" v-if="dialogCharacteristics" max-width="500px">
            <v-card>
                <v-container>
                    <s-crud
                        :config="configCharacteristics"
                        title="Características"
                        :filter="filterCharacteristicsOrAccessory"
                        close
                        add
                        edit
                        remove
                        @save="saveCharacteristics($event)"
                        @close="dialogCharacteristics = false"
                        ref="scrudCharacteristics"
                    >
                        <template v-slot="props">
                            <v-container>
                                <v-row >                                        
                                    <v-col cols="12" class="s-col-form" md="6">
                                        <s-select-definition
                                            :def="1532"
                                            :dgr="CgeIDGroup"
                                            label="Nombre"
                                            v-model="props.item.EctCharacteristics"
                                            :add="$fun.isAdmin()"
                                        />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="6">
                                        <s-text                               
                                            label="Valor" 
                                            v-model="props.item.EctValueCharacteristics"/>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="12">
                                        <s-date
                                            v-model="props.item.EctDate"
                                            label="Fecha Adquisición"
                                            />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </s-crud>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialogAccessory" v-if="dialogAccessory" max-width="800px">
            <v-card>
                <v-container>
                    <s-crud
                        :config="configAccessory"
                        title="Accesorios"
                        :filter="filterCharacteristicsOrAccessory"
                        close
                        add
                        edit
                        remove
                        @save="saveAccessory($event)"
                        @close="dialogAccessory = false"
                        ref="scrudAccessory"
                    >
                        <template v-slot="props">
                            <v-container>
                                <v-row>                                        
                                    <v-col cols="12" class="s-col-form" md="3">
                                        <s-select-definition                                
                                            :def="1543"
                                            label="Accesorio"
                                            v-model="props.item.EayAccessory"
                                            />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="3" v-if="!props.item.EayObservation">
                                        <s-select-definition                                
                                            :def="1533"
                                            label="Marca"
                                            v-model="props.item.EayBrand"
                                            :add="$fun.isAdmin()"
                                            clearable
                                            />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="3" v-if="!props.item.EayObservation">
                                        <s-select-definition                                
                                        :def="1540"
                                        label="Modelo"
                                        v-model="props.item.EayModel"
                                        :add="$fun.isAdmin()"
                                        clearable
                                        />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="3" v-if="!props.item.EayObservation">
                                        <s-text
                                            :disabled="props.item.EayID ? true : false"
                                            label="Serie"
                                            v-model="props.item.EaySerie"/>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="9" v-if="validatedItems(props.item)">
                                        <s-text
                                            label="Descripción"
                                            v-model="props.item.EayObservation"/>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="3">
                                        <s-date
                                            v-model="props.item.EayDate"
                                            label="Fecha Adquisición"
                                            />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="3">
                                        <s-select-definition                               
                                            :def="1539"
                                            label="Estado"
                                            v-show="props.item.EayID ? true : false"
                                            v-model="props.item.EayStatus"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </s-crud>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialogLicense" v-if="dialogLicense" max-width="600px">
            <v-card>
                <v-container>
                    <s-crud
                        :config="configLicense"
                        title="Licencias"
                        :filter="filterCharacteristicsOrAccessory"
                        close
                        add
                        edit
                        remove
                        @save="saveLicense($event)"
                        @close="dialogLicense = false"
                        ref="scrudLicense"
                    >
                        <template v-slot="props">
                            <v-container>
                                <v-row>                                        
                                    <v-col cols="12" class="s-col-form" md="4">
                                        <s-select-definition                                
                                            :def="1545"
                                            label="Licencia"
                                            v-model="props.item.TelLicence"
                                            />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="4">
                                        <s-select-definition                                
                                            :def="1546"
                                            label="Año"
                                            v-model="props.item.TelAnio"
                                            :add="$fun.isAdmin()"
                                            />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="4">
                                        <s-date
                                            v-model="props.item.TelExpirationDate"
                                            label="Fecha Expiración"
                                            />
                                    </v-col>
                                    <v-col cols="12" class="s-col-form" md="12">
                                        <s-textarea
                                            :disabled="props.item.EayID ? true : false"
                                            label="Observación"
                                            v-model="props.item.TelObservation"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </s-crud>
                </v-container>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>

import SText from '../../../components/Utils/SText.vue';
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import TicEquipment from '@/services/InformationTechnology/TicEquipmentService';
import TicEquipmentCharacteristic from '@/services/InformationTechnology/EquimentCharacteristicService';
import TicEquimentAccessoryService from '../../../services/InformationTechnology/EquimentAccessoryService';
import TicEquipmentLicenseService from '../../../services/InformationTechnology/TicEquipmentLicenseService';

export default {
    components: {
        SText,
        sToolbarPerson
    },
    data() {
        return {
            loading: false,
            dialogExcel: true,
            openMass: false,
            CgeIDGroup: 0,
            CgeAssigns: true,
            dialogCharacteristics: false,
            dialogAccessory: false,
            dialogLicense: false,
            filter: {
                CgeDateFrom: this.$fun.getDate(""),
                CgeDateUntil: this.$fun.getDate(""),
                CgeTypeAssigns: null,
                AreID: null,
                CgeStatus: null,
                EanZone: null
            },
            filterCharacteristicsOrAccessory: {
                CgeID: ""
            },
            config: {
                service: TicEquipment,
                model: {
                    CgeID: "ID",
                    AsgCgeDate: "",
                    AsgCharacteristicsOrAccessory: "",
                    AsgCgeAssigns: "",
                    AsgStatusName: "",
                    AsgCgeIDAnt: ""
                },
                headers: [
                    { text: "ID", value: "CgeID", draggable: false },
                    { text: "Nombre del Equipo", value: "CgeTypeEquipmentName" },
                    { text: "Marca", value: "CgeBrandName" },
                    { text: "Modelo", value: "CgeModelName" },
                    { text: "Serie", value: "CgeSerie" },
                    { text: "Fecha Adquisición", value: "AsgCgeDate" },
                    { text: "Asignable a", value: "CgeTypeAssignsName" },
                    { text: "Asignable", value: "AsgCgeAssigns", width: 100, draggable: false },
                    { text: "Estado", value: "AsgStatusName", width: 100, draggable: false },
                    { text: "¿Anterior?", value : "AsgCgeIDAnt", align: "center", width: 80 },
                    { text: "Agregar", value: "AsgCharacteristicsOrAccessory", align: "center", width: 160, draggable: false },
                ]
            },
            configCharacteristics: {
                service: TicEquipmentCharacteristic,
                model: {
                    EctID: "ID",
                    EctDate: "date"
                },
                headers: [
                    { text: "Nombre", value: "EctCharacteristicsName" },
                    { text: "Valor", value: "EctValueCharacteristics" },
                    { text: "Fecha Adquisición", value: "EctDate" },
                ]
            },
            headerDetail:[
                { text: "VALOR", value: "VALOR_EQUIPO", width: 60 },
                { text: "NOMBRE EQUIPO", value: "NOMBRE_EQUIPO", width: 60 },
                { text: "VALOR", value: "VALOR_MARCA", width: 60 },
                { text: "MARCA", value: "MARCA", width: 60 },
                { text: "MODELO", value: "MODELO", width: 60 },
                { text: "SERIE", value: "SERIE", width: 60 },
                { text: "FECHA ADQUISION", value: "FECHA_ADQUISION", width: 60 },
                { text: "ASIGNABLE A", value: "ASIGNABLE_A", width: 60 },
                { text: "ASIGNABLE", value: "ASIGNABLE", width: 60 },
            ],
            headers: [
                {
                    column	: 	"VALOR_EQUIPO",
                    value	: 	"VALOR_EQUIPO",
                    text 	: 	"VALOR"
                },
                {
                    column	: 	"NOMBRE_EQUIPO",
                    value	: 	"NOMBRE_EQUIPO",
                    text 	: 	"NOMBRE DEL EQUIPO"
                },
                {
                    column	: 	"VALOR_MARCA",
                    value	: 	"VALOR_MARCA",
                    text 	: 	"VALOR"
                },
                {
                    column	: 	"MARCA",
                    value	: 	"MARCA",
                    text 	: 	"MARCA"
                },	
                {
                    column	: 	"MODELO",
                    value	: 	"MODELO",
                    text 	: 	"MODELO"
                },
                {
                    column	: 	"SERIE",
                    value	: 	"SERIE",
                    text 	: 	"SERIE"
                },	
                {
                    column	: 	"FECHA_ADQUISION",
                    value	: 	"FECHA_ADQUISION",
                    text 	: 	"FECHA ADQUISION",
                    date	: 	true
                },		
                {
                    column	: 	"ASIGNABLE_A",
                    value	: 	"ASIGNABLE_A",
                    text 	: 	"ASIGNABLE A"
                },	
                {
                    column	: 	"ASIGNABLE",
                    value	: 	"ASIGNABLE",
                    text 	: 	"ASIGNABLE"
                },		
            ],
            configAccessory: {
                service: TicEquimentAccessoryService,
                model: {
                    EayID: "ID",
                },
                headers: [
                    { text: "Nombre", value: "EayAccessoryName" },
                    { text: "Marca", value: "EayBrandName" },
                    { text: "Modelo", value: "EayModelName" },
                    { text: "Serie", value: "EaySerie" },
                    { text: "Observación", value: "EayObservation" },
                ]
            },
            configLicense: {
                service: TicEquipmentLicenseService,
                model: {
                    TelID: "ID",
                    TelExpirationDate: "date",
                },
                headers: [
                    { text: "Nombre", value: "TelLicenceName" },
                    { text: "Año", value: "TelAnioName" },
                    { text: "Fecha de Expiración", value: "TelExpirationDate" },
                    { text: "Observación", value: "TelObservation" },
                ]
            }
        }
    },
    methods: {
        validatedItems(item) {
            if(!item.EayBrand && !item.EayModel && !item.EaySerie){
                return true;
            }else{
                return false;
            }
        },
        addEvent() {
            this.CgeAssigns = true;
            return true;
        },
        assignFun(item) {
            this.CgeAssigns = item.CgeAssigns == 0 ? false : true;
            return true;
        },
        save(item){
            item.UsrCreateID = this.$fun.getUserID()
            item.CgeAssigns = this.CgeAssigns ? 1 : 0;
            item.CgeSerie = this.$fun.trimFull(item.CgeSerie, true);
            item.CgeStatus = 1;
            // console.log("Save:",item);
            if(item.CgeModel == "" || item.CgeSerie.trim() == ""){
                this.$fun.alert("Debe de llenar todos los campos", "warning");
                return;
            }
            item.save();
        },
        AsgCharacteristics(row){
            this.filterCharacteristicsOrAccessory.CgeID = row.CgeID;
            this.CgeIDGroup = row.DedHelper2 ? parseInt(row.DedHelper2) : 0;
            this.dialogCharacteristics = true;
        },
        saveCharacteristics(item){
            item.UsrCreateID = this.$fun.getUserID()
            item.CgeID = this.filterCharacteristicsOrAccessory.CgeID
            item.save();
        },
        openMassRegistrations(){
            this.openMass = true;
        },
        clear(){
            this.dialogExcel = false;
            setTimeout(() => {
            this.dialogExcel = true;
            }, 10);
        },
        async saveMassive(items){ 
            let itemsCount = items.length;
            let count = 0;

            // console.log("items", items);
            if(items.length == 0){
                this.$fun.alert("No hay datos para registrar", "warning");
                return;
            } 
            if(this.loading){
                this.$fun.alert("Procesando datos", "warning");
                return;
            } 

            this.loading = true;

            for (const item of items) {
                const equipment = {
                    CgeTypeEquipment: item.VALOR_EQUIPO,
                    CgeTypeAssigns: item.ASIGNABLE_A,
                    CgeBrand: item.VALOR_MARCA,
                    CgeModel: item.MODELO,
                    CgeSerie: item.SERIE? this.$fun.trimFull((item.SERIE).toString(), true) : "",
                    CgeAssigns: item.ASIGNABLE,
                    CgeDate: item.FECHA_ADQUISION,
                    UsrCreateID: this.$fun.getUserID(),
                    CgeStatus: 1
                };

                if(equipment.CgeSerie == ""){
                    this.$fun.alert("Debe de llenar la serie", "warning");
                    return;
                }
                
                try {
                    // console.log(equipment);
                    const response = await TicEquipment.save(equipment, this.$fun.getUserID());
                    if (response.status == 200) {
                        count++;
                    }
                    // console.log("Registro de Persona", response.data);
                } catch (error) {
                    console.error("Error al registrar persona", item, error);
                }
            }

            this.loading = false;
            if(itemsCount != count){
                this.$fun.alert("No se pudo registrar todos los equipos", "warning");
            }else{
                this.$fun.alert("Guardado Correctamente", "success");
            }
            this.$refs.scrudEquipment.refresh();
            this.openMass = false;
        },
        AsgAccessory(row){
            this.filterCharacteristicsOrAccessory.CgeID = row.CgeID;
            this.dialogAccessory = true;
        },
        saveAccessory(item){
            item.UsrCreateID = this.$fun.getUserID()
            item.CgeID = this.filterCharacteristicsOrAccessory.CgeID
            item.save();
        },
        AsgLicense(row){
            this.filterCharacteristicsOrAccessory.CgeID = row.CgeID;
            this.dialogLicense = true;
        },
        saveLicense(item){
            item.UsrCreateID = this.$fun.getUserID()
            item.CgeID = this.filterCharacteristicsOrAccessory.CgeID
            item.TelStatus = 1
            item.save();
        },
    },
    watch: {
        'filter.CgeTypeAssigns'(){
            this.filter.AreID = null;
            this.filter.EanZone = null;
        }
    }
}

</script>